<template>
  <div class="components_list" @click="showListComponents=true" :class="{ 'expand': showListComponents }" :title="$t('designer.list_components.hover')">
    <div class="from">
      <div class="from-contents">
        <i class="avatar plk icon-plk">&#xe805;</i>
      </div>
    </div>
    <div class="to">
      <div class="to-contents">
        <div class="top">
          <div class="name-large">{{$t('designer.list_components.title')}}</div>
          <div :title="$t('designer.list_components.close')" class="x-touch"  @click.stop="showListComponents=false">
            <i class="pointer fas fa-times-circle"></i>
          </div>
        </div>
        <div class="search-box">
          <form ref="addComponent" @submit.prevent="addAvaliableComponent" class="m-0">
            <input ref="filter-search" v-model="filterComponent" type="text" :placeholder="$t('designer.list_components.search')"/>
            <button v-if="filterComponent.startsWith('http')" type="submit" class="addComponent">
              <i class="fas fa-plus pointer"></i>
            </button>
          </form>
        </div>
        <div class="bottom">
          <div :title="$t(item.name)" class="item pointer" v-for="item in filterAvaliableComponents" :key="item.id" @click="addObjectInView(selectedPage, item.id)">
            <img v-if="packages[item.id]" :src="packages[item.id].icon"/>
            <div>{{$t(item.name)}}<i v-if="item.id.startsWith('http')" :title="$t('designer.list_components.deletecomponent')" @click="deleteAvaliableComponent(item.id)" class="pointer fas fa-trash-alt pt-2 ml-2 trashbtn"/></div>
          </div>
          <div class="p-2">Icons designed by <a href="https://www.flaticon.es/autores/freepik" title="Freepik">Freepik</a> and <a href="https://www.flaticon.es/autores/mynamepong" title="mynamepong">Mynamepong</a> </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Vue from 'vue'
import i18n from './i18n'
import { Store, DESIGN } from '@/store'
export default {
  name: 'List',
  props: {
    selectedPage: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showListComponents: false,
      filterComponent: ''
    }
  },
  computed: {
    avaliableComponents () {
      return Store.getters[DESIGN.GETTERS.GET_AVAILABLE_COMPONENTS]()
    },
    filterAvaliableComponents () {
      if (this.filterComponent) {
        const filter = this.filterComponent.toLowerCase()
        return this.avaliableComponents.filter(e => e.name.toLowerCase().indexOf(filter) !== -1 || e.id.toLowerCase().indexOf(filter) !== -1)
      } else {
        return this.avaliableComponents
      }
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.avaliableComponents.forEach(e => this.loadPackage(e.id))
    },
    addAvaliableComponent () {
      if (this.filterComponent.startsWith('http')) {
        this.$modal.show(
          {
            template: `
            <div class="dialog-create">
              <form @submit.prevent="confirm" class="m-0">
                <div class="dialog-content">
                  <div class="vue-dialog-content-title">{{title}}</div>
                  <div>{{text}}</div>
                  <input v-model="filename" required class="u-full-width mt-3" type="text" :placeholder="$t('designer.list_components.createcomponent.componentname')" id="fileName">
                </div>
                <div class="vue-dialog-buttons">
                  <button type="button" @click="cancel" class="vue-dialog-button" style="flex: 1 1 50%;">
                    {{$t('designer.list_components.createcomponent.cancel')}}
                  </button>
                  <button type="submit" class="vue-dialog-button" style="flex: 1 1 50%;">
                    {{$t('designer.list_components.createcomponent.confirm')}}
                  </button>
                </div>
              </form>
            </div>
          `,
            props: ['name', 'title', 'text', 'onConfirm'],
            data () {
              return {
                filename: ''
              }
            },
            methods: {
              confirm () {
                if (this.onConfirm) this.onConfirm(this.filename)
              },
              cancel () {
                this.$emit('close')
              }
            }
          },
          {
            text: this.$t('designer.list_components.createcomponent.text'),
            title: this.$t('designer.list_components.createcomponent.title'),
            name: 'createFile',
            onConfirm: (name) => {
              Store.commit({
                type: DESIGN.MUTATIONS.CREATE_AVAILABLE_COMPONENTS,
                id: this.filterComponent,
                name
              })
              Vue.set(this, 'filterComponent', '')
              this.$modal.hideAll()
            }
          },
          { height: 'auto', draggable: true, adaptive: true, class: 'vue-dialog' }
        )
      }
    },
    addObjectInView (view, id) {
      this.$modal.show('dialog', {
        title: this.$t('designer.list_components.addObjectInView.title'),
        text: this.$t('designer.list_components.addObjectInView.text'),
        buttons: [
          {
            title: this.$t('designer.list_components.addObjectInView.modal.cancel'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('designer.list_components.addObjectInView.modal.confirm'),
            handler: () => {
              Store.commit({
                type: DESIGN.MUTATIONS.ADD_OBJECT,
                view,
                object: {
                  type: id
                }
              })
              this.$modal.hide('dialog')
              this.$emit('changedPage', this.selectedPage)
            }
          }
        ]
      })
    },
    deleteAvaliableComponent (id) {
      if (id) {
        this.$modal.show('dialog', {
          title: this.$t('designer.list_components.deleteAvaliableComponents.title'),
          text: this.$t('designer.list_components.deleteAvaliableComponents.text'),
          buttons: [
            {
              title: this.$t('designer.list_components.deleteAvaliableComponents.modal.cancel'),
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            {
              title: this.$t('designer.list_components.deleteAvaliableComponents.modal.confirm'),
              handler: () => {
                Store.commit({
                  type: DESIGN.MUTATIONS.DELETE_AVAILABLE_COMPONENTS,
                  id
                })
                this.$modal.hide('dialog')
              }
            }
          ]
        })
      }
    }
  },
  watch: {
    showListComponents () {
      if (this.showListComponents) {
        this.$nextTick(() => {
          this.$refs['filter-search'].focus()
        })
      }
    },
    avaliableComponents () {
      this.avaliableComponents.forEach(e => this.loadPackage(e.id))
    }
  }
}
</script>

<style lang="scss">
  .dialog-create {
    .dialog-content {
      padding: 2rem;
    }
  }
</style>
<style scoped lang="scss">
  .components_list {
    background: #DEDBDF;
    border-radius: 16px;
    height: 32px;
    overflow: hidden;
    position: relative;
    width: 40px;
    -webkit-tap-highlight-color: transparent;
    transition: width 300ms cubic-bezier(0.4, 0.0, 0.2, 1), height 300ms cubic-bezier(0.4, 0.0, 0.2, 1), box-shadow 300ms cubic-bezier(0.4, 0.0, 0.2, 1), border-radius 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
    .addComponent {
      position: fixed;
      z-index: 999;
      right: 0;
      border: 0;
      padding: 0 5px;
      margin-top: -5px;
    }
    &:not(.expand) {
      cursor: pointer;
      &:hover {
        background: #C2C0C2;
      }
    }
  }
  .from {
    position: absolute;
    transition: opacity 200ms 100ms cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  .from-contents {
    display: flex;
    flex-direction: row;
    transform-origin: 0 0;
    transition: transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
  }
  .to {
    opacity: 0;
    height: 100%;
    position: absolute;
    transition: opacity 100ms cubic-bezier(0.4, 0.0, 1, 1);
  }
  .to-contents {
    transform: scale(.55);
    height: 100%;
    transform-origin: 0 0;
    transition: transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
  }
  .avatar {
    border-radius: 12px;
    line-height: 2.5rem;
    font-size: 2.5rem;
    left: 4px;
    position: relative;
    top: 4px;
    width: 24px;
  }
  .top {
    background: $color-base;
    display: flex;
    flex-direction: row;
    height: 70px;
    transition: height 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
    width: 300px;
  }
  .name-large {
    color: #efd8ef;
    font-size: 16px;
    line-height: 70px;
    margin-left: 20px;
  }
  .x-touch {
    align-items: center;
    align-self: center;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    margin-left: auto;
    width: 50px;
    color: white;
    &:hover {
      color: black;
    }
  }
  .bottom {
    background: #FFF;
    color: #444247;
    font-size: 14px;
    height: calc(100% - 100px);
    overflow: scroll;
    padding-top: 5px;
    width: 300px;
  }
  .item {
    padding: 10px 20px;
    align-items: center;
    text-align: center;
    flex-direction: column;
    .trashbtn {
      display: none;
    }
    &:hover {
      background: #efefef;
      .trashbtn {
        display: inline;
      }
    }
    img {
      max-width: 30%;
    }
  }
  .components_list.expand {
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.10), 0 6px 6px rgba(0,0,0,0.16);
    height: 90vh;
    width: 300px;
  }
  .expand {
    .from {
      opacity: 0;
      transition: opacity 100ms cubic-bezier(0.4, 0.0, 1, 1);
    }
    .from-contents {
      transform: scale(1.91);
    }
    .to {
      opacity: 1;
      transition: opacity 200ms 100ms cubic-bezier(0.0, 0.0, 0.2, 1);
    }
    .to-contents {
      transform: scale(1);
    }
  }
  .search-box {
    input {
      width: 100%;
      height: 30px;
      margin: 0;
      border-radius: 0;
      &:focus {
        border: 0px;
        border-bottom: 1px solid $color-basedark;
        outline: 0;
      }
      &:active {
        border: 0px;
        border-bottom: 1px solid $color-basedark;
        outline: 0;
      }
    }
    input[type="email"] {
      &:focus {
        border: 0px;
        border-bottom: 1px solid $color-basedark;
        outline: 0;
      }
    }
    input[type="number"] {
      &:focus {
        border: 0px;
        border-bottom: 1px solid $color-basedark;
        outline: 0;
      }
    }
    input[type="search"] {
      &:focus {
        border: 0px;
        border-bottom: 1px solid $color-basedark;
        outline: 0;
      }
    }
    input[type="text"] {
      &:focus {
        border: 0px;
        border-bottom: 1px solid $color-basedark;
        outline: 0;
      }
    }
    input[type="tel"] {
      &:focus {
        border: 0px;
        border-bottom: 1px solid $color-basedark;
        outline: 0;
      }
    }
    input[type="url"] {
      &:focus {
        border: 0px;
        border-bottom: 1px solid $color-basedark;
        outline: 0;
      }
    }
    input[type="password"] {
      &:focus {
        border: 0px;
        border-bottom: 1px solid $color-basedark;
        outline: 0;
      }
    }
  }
  .pointer {
    cursor: pointer;
  }
</style>
