<template>
  <div>
    <div class="plk_documentation" v-html="readmeList[type]"></div>
  </div>
</template>

<script>
import i18n from './i18n'
export default {
  name: 'documentation',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.loadReadme(this.type)
    }
  },
  watch: {
    type () {
      this.loadReadme(this.type)
    }
  }
}
</script>
<style scoped lang="scss">
  .u-full-width {
    resize: vertical;
    min-height: 50vh;
  }
  .plk_documentation {
    margin-bottom: 10vh;
  }
</style>
