<template>
    <div class="navbarselector select">
      <i @click="emitPage(selectedPage)" class="fas fa-sync-alt"></i>
      {{selectedPage}}
      <i class="fas arrow"></i>
      <div class="options">
        <div v-if="!createPage" class="text-center" @click="openCreateView()"><i class="fas fa-plus-circle"></i> {{$t('designer.navbarselector.create')}}</div>
        <div v-if="createPage" class="text-center pt-3 row">
          <form @submit.stop="createView(newPageName)" class="mb-0">
            <input v-model="newPageName" ref="createPageInput" class="viewname px-2 m-0" type="text" :placeholder="$t('designer.navbarselector.viewname')">
            <button type="submit" class="savebutton px-2"> {{$t(newPageName.length ? 'designer.navbarselector.save' : 'designer.navbarselector.cancel')}}</button>
          </form>
        </div>
        <div @click="editingPage !== view ? selectedPage=view : 0" class="item" v-for="view in views" :key="view">
          <span v-if="editingPage !== view">{{view}}</span>
          <div v-if="editingPage === view" class="text-center pt-3 row">
            <form @submit.stop="editView(view, newName)">
              <input v-model="newName" class="viewname px-2 m-0" type="text" :placeholder="$t('designer.navbarselector.viewname')">
              <button type="submit" class="savebutton px-2"> {{$t(editingPage === view ? 'designer.navbarselector.save' : 'designer.navbarselector.cancel')}}</button>
            </form>
          </div>
          <i @click.stop="deleteView(view)" v-if="view !== selectedPage" class="fas fa-trash-alt" :title="$t('designer.navbarselector.delete')"></i>
          <i @click.stop="cloneView(view)" class="fas fa-copy" :title="$t('designer.navbarselector.copy')"></i>
          <i @click.stop="showPageCode(view)" class="fas fa-code" :title="$t('designer.navbarselector.showcode')"></i>
          <i @click.stop="embebedPage(view)" class="fas fa-solid fa-window-maximize" :title="$t('designer.navbarselector.embebed')"></i>
          <i @click.stop="editingPage = view; newName = view" v-if="view !== selectedPage" class="fas fa-edit" :title="$t('designer.navbarselector.edit')"></i>
        </div>
      </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { Store, DESIGN } from '@/store'
import i18n from './i18n'
import { VIEWS } from '@/router/design'

export default {
  name: 'NavbarSelector',
  props: {},
  data () {
    return {
      selectedPage: undefined,
      createPage: false,
      newPageName: '',
      editingPage: '',
      newName: ''
    }
  },
  computed: {
    views () {
      return Store.getters[DESIGN.GETTERS.GET_ALL_VIEWS]()
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      Vue.set(this, 'selectedPage', this.$route.query.selectedPage || (this.views.length ? this.views[0] : undefined))
    },
    openCreateView () {
      this.createPage = true
      this.$nextTick(() => {
        this.$refs.createPageInput.focus()
      })
    },
    createView (view) {
      if (view) {
        Store.commit({
          type: DESIGN.MUTATIONS.CREATE_VIEW,
          view,
          actions: [],
          metaInfo: {},
          viewClass: ''
        })
      }
      Vue.set(this, 'createPage', false)
      Vue.set(this, 'newPageName', '')
    },
    editView (view, newView) {
      if (view && newView) {
        Store.commit({
          type: DESIGN.MUTATIONS.CHANGE_VIEW_NAME,
          view,
          newView
        })
      }
      Vue.set(this, 'editingPage', undefined)
      Vue.set(this, 'newName', undefined)
    },
    cloneView (view) {
      if (view) {
        Store.commit({
          type: DESIGN.MUTATIONS.CLONE_VIEW,
          view
        })
      }
    },
    showPageCode (view) {
      if (view) {
        this.$router.push({ name: VIEWS.PAGE_CODE.name, query: { id: view } })
      }
    },
    deleteView (view) {
      if (view) {
        this.$modal.show('dialog', {
          title: this.$t('designer.navbarselector.item.delete.title'),
          text: this.$t('designer.navbarselector.item.delete.text'),
          buttons: [
            {
              title: this.$t('designer.navbarselector.modal.cancel'),
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            {
              title: this.$t('designer.navbarselector.modal.confirm'),
              handler: () => {
                Store.commit({
                  type: DESIGN.MUTATIONS.DELETE_VIEW,
                  view
                })
                this.$modal.hide('dialog')
              }
            }
          ]
        })
      }
    },
    emitPage (page) {
      if (page !== this.$route.query.selectedPage) this.$router.push({ query: { selectedPage: page } })
      this.$emit('changedPage', page)
    },
    embebedPage (page) {
      this.$emit('embebedPage', page)
    }
  },
  watch: {
    selectedPage () {
      this.emitPage(this.selectedPage)
    }
  }
}
</script>
<style lang="scss" scoped>
  .navbarselector.select {
    height: 30px;
    cursor: pointer;
    margin: 0;
    margin-top: 10px;
    width: 50%;
    background: white;
    border-radius: 5px;
    border: 1px solid #cdcdcd;
    text-align: center;
    line-height: 30px;
    &:hover {
      border-radius: 5px 5px 0px 0px;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(130,130,130,1);
      -moz-box-shadow: 1px 1px 5px 0px rgba(130,130,130,1);
      box-shadow: 1px 1px 5px 0px rgba(130,130,130,1);
      .options {
        height: auto;
        max-height: 40vh;
        border: 1px solid #f8f9fa;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .arrow {
        &::before {
          content: "\f077";
        }
      }
      .fa-sync-alt {
        visibility: visible;
      }
    }
    .options {
      &:hover {
        height: auto;
        max-height: 40vh;
        border: 1px solid #f8f9fa;
      }
      max-height: 0px;
      overflow: scroll;
      background: white;
      width: 100%;
      position: relative;
      z-index: 999;
      margin-left: -1px;
      text-align: left;
      margin-top: 2px;
      border-radius: 0px 0px 5px 5px;
      -webkit-box-shadow: 1px 1px 5px 0px rgba(130,130,130,1);
      -moz-box-shadow: 1px 1px 5px 0px rgba(130,130,130,1);
      box-shadow: 1px 1px 5px 0px rgba(130,130,130,1);
      -webkit-transition: display 0s ease-in-out;
      -webkit-transition: max-height .5s ease-in-out;
      -moz-transition: max-height .5s ease-in-out;
      -ms-transition: max-height .5s ease-in-out;
      -o-transition: max-height .5s ease-in-out;
      transition: max-height .5s ease-in-out;
      .item {
        &:last-child {
          border-bottom: 0;
        }
        &:hover {
          .fas {
            display: block;
          }
          background: #efefef;
        }
        .fas {
          float: right;
          display: none;
          line-height: 35px;
          margin-left: 10px;
          color: #5a5959;
        }
        cursor: pointer;
        padding: 0 10px;
        line-height: 35px;
        border-bottom: solid 1px #efefef;
      }
    }
    .arrow {
      &::before {
        content: "\f078";
      }
      float: right;
      padding-top: 9px;
      margin-right: 1rem;
    }
    .fa-sync-alt {
      visibility: hidden;
      padding-top: 9px;
      margin-left: 1rem;
      float: left;
      color: #cdcdcd;
    }
  }
  .viewname {
    width: 78%;
    height: 35px;
    margin-right: 2px;
  }
  .savebutton {
    width: 19%;
    height: 35px;
    margin-left: 2px;
    line-height: 35px;
  }
  .browser_mobile {
    .viewname {
      width: 100%;
      margin: 0 !important;
    }
    .savebutton {
      width: 100%;
      margin: 0 !important;
    }
  }
</style>
