<template>
  <div class="plk_design">
    <Menu></Menu>
    <div class="viewer">
      <ObjectList class="object_list" v-if="selectedPage && !showConfig" :selectedPage="selectedPage" @changedPage="realoadView"/>
      <div class="window_creator" :class="{ 'full_screen': maximize.screen, 'browser_desktop': !maximize.screen && typeView === 1, 'browser_tablet': !maximize.screen && typeView === 2, 'browser_mobile': !maximize.screen && typeView === 3 }">
        <div class="address_bar justify_content_between">
          <div class="bar_dots mr-auto">
            <span class="maximize" :title="$t(maximize.screen ? 'designer.design.minimize' : 'designer.design.maximize')" @click="maximize.screen=!maximize.screen"></span>
            <a :href="selectedPage" :title="$t('designer.design.showNewPage')" target="_blank"><span class="openNewTab"></span></a>
          </div>
          <NavbarSelector class="navbar" @changedPage="realoadView" @embebedPage="embebedPage"/>
          <div class="bar_icons ml-auto">
            <i @click="changeView(1)" :title="$t('designer.design.showDesktop')" class="fas fa-desktop" :class="{ 'selected': typeView === 1 }"></i>
            <i @click="changeView(2)" :title="$t('designer.design.showTablet')" class="fas fa-tablet-alt" :class="{ 'selected': typeView === 2 }"></i>
            <i @click="changeView(3)" :title="$t('designer.design.showMobile')" class="fas fa-mobile-alt" :class="{ 'selected': typeView === 3 }"></i>
          </div>
        </div>
        <div class="html_content">
          <object id="page" v-if="selectedPage" :data="selectedPage" width="100%" height="100%" type="text/html">
            {{$t('designer.design.imposibleLoadEditor')}}
          </object>
        </div>
      </div>
    </div>
    <div class="config_area" :class="{ 'show': showConfig, 'maximize': maximize.config }" @keydown.esc="maximize.config=false;showConfig=false" tabindex="0">
      <div class="body" ref="bodyContent">
        <div class="fixed pull-right">
          <i class="mr-3 mt-1" :title="$t(showDoc ? 'designer.design.showCode' : 'designer.design.showDoc')" @click="showDoc=!showDoc" :class="showDoc ? 'fas fa-code' : 'fas fa-book'"></i>
          <i :title="$t(maximize.config ? 'designer.design.minimize' : 'designer.design.maximize')" @click="maximize.config=!maximize.config" :class="maximize.config ? 'fa-compress-alt' : 'fa-expand-alt'" class="fas mr-3 mt-1"></i>
          <i :title="$t('designer.design.closeconfig')" @click="maximize.config=false;showConfig=false" class="fas fa-times mr-4 mt-1"></i>
        </div>
        <div class="config_content">
          <Configuration v-if="!showDoc && selectedObject" :type="selectedObject.type" :initValue="selectedObject" @changeValue="saveConfig"/>
          <Documentation v-if="showDoc && selectedObject" :type="selectedObject.type"/>
        </div>
      </div>
    </div>
    <modal name="show-embebed-code" scrollable draggable height="auto" width="50%">
      <div class="p-3">
        <div class="vue-dialog-content-title">{{$t('designer.design.embebed.title')}}</div>

        <!-- Dependency code -->
        <div class="twelve mt-4">
          <label>{{$t('designer.design.embebed.dependencycode')}}</label>
        </div>
        <div class="ten ml-3">
          <textarea class="u-full-width" type="text" v-model="dependencyCode"></textarea>
        </div>
        <div class="two columns ml-4">
          <input type="submit" :value="this.$t(`designer.design.embebed.${copied['dependency'] ? 'copied' : 'copy'}`)" :class="{ 'copied': copied['dependency'] }" @click="copyClipboard(dependencyCode, 'dependency')">
        </div>

        <!-- Embebed public code -->
        <div class="twelve mt-3">
          <label>{{$t('designer.design.embebed.apicode')}}</label>
        </div>
        <div class="ten ml-3">
          <input class="u-full-width" type="text" v-model="apiCode">
        </div>
        <div class="two columns ml-4">
          <input type="submit" :value="this.$t(`designer.design.embebed.${copied['api'] ? 'copied' : 'copy'}`)" :class="{ 'copied': copied['api'] }" @click="copyClipboard(apiCode, 'api')">
        </div>

        <!-- Embebed public code -->
        <div class="twelve mt-3">
          <label>{{$t('designer.design.embebed.embebedcode')}}</label>
        </div>
        <div class="ten ml-3">
          <input class="u-full-width" type="text" v-model="publicCode">
        </div>
        <div class="two columns ml-4">
          <input type="submit" :value="this.$t(`designer.design.embebed.${copied['public'] ? 'copied' : 'copy'}`)" :class="{ 'copied': copied['public'] }" @click="copyClipboard(publicCode, 'public')">
        </div>

        <!-- Embebed private code -->
        <div class="twelve mt-3">
          <label>{{$t('designer.design.embebed.embebedprivatecode')}}</label>
        </div>
        <div class="ten ml-3">
          <input class="u-full-width" type="text" v-model="privateCode">
        </div>
        <div class="two columns ml-4">
          <input type="submit" :value="this.$t(`designer.design.embebed.${copied['private'] ? 'copied' : 'copy'}`)" :class="{ 'copied': copied['private'] }" @click="copyClipboard(privateCode, 'private')">
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue'
import i18n from './i18n'
import _ from 'lodash'
import { Store, DESIGN } from '@/store'
import Configuration from '@/views/design/components/configurations/Configuration'
import Documentation from '@/views/design/components/documentations/Documentation'
import NavbarSelector from './components/navbars/NavbarSelector'
import Menu from './components/menus/Menu'
import ObjectList from '../viewer/objectLoaders/design/List'
import { generatePrivateTheme, generatePublicTheme, generateAPITheme, generateDependencyTheme } from '@/plugins/themeembebed'
import { EDIT_OBJECT, EMBEBED_OBJECT, MOVE_LEFT, MOVE_RIGHT, MOVE_UP, MOVE_DOWN, DELETE_OBJECT, CLONE_OBJECT } from '../viewer/objectLoaders/design/ObjectLoader'
import { APP_STYLES_CONFIG_FILE, APP_COLORS_CONFIG_FILE, APP_JAVASCRIPT_CONFIG_FILE } from '@/enviroment/app'

export default {
  name: 'design',
  components: {
    Configuration,
    Documentation,
    NavbarSelector,
    Menu,
    ObjectList
  },
  data () {
    return {
      copied: {},
      typeView: 1,
      showCopyCode: false,
      maximize: {
        screen: false,
        config: false
      },
      showConfig: false,
      selectedObject: undefined,
      objectPosition: -1,
      selectedPage: undefined,
      showDoc: false,
      embebedData: '',
      cmOptions: {
        tabSize: 2,
        autoCloseTags: true,
        mode: 'text/html',
        theme: 'elegant',
        lineNumbers: true,
        styleActiveLine: true,
        line: true,
        matchBrackets: true
      }
    }
  },
  computed: {
    privateCode: {
      get () {
        return generatePrivateTheme(this.embebedData)
      },
      set () {}
    },
    publicCode: {
      get () {
        return generatePublicTheme(this.embebedData)
      },
      set () {}
    },
    apiCode: {
      get () {
        return generateAPITheme(this.embebedData)
      },
      set () {}
    },
    dependencyCode: {
      get () {
        return generateDependencyTheme(this.embebedData)
      },
      set () {}
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.removeObjectListener()
      this.addObjectListener()
    },
    onDestroyComponent () {
      this.removeObjectListener()
    },
    addObjectListener () {
      const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
      const eventer = window[eventMethod]
      const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
      eventer(messageEvent, this.objectListener)
    },
    removeObjectListener () {
      const eventMethod = window.addEventListener ? 'removeEventListener' : 'detachEvent'
      const eventer = window[eventMethod]
      const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
      eventer(messageEvent, this.objectListener)
    },
    editComponent (position, component) {
      Vue.set(this, 'showConfig', !this.showConfig)
    },
    copyClipboard (value, instanceCopy) {
      const dummy = document.createElement('textarea')
      document.body.appendChild(dummy)
      dummy.value = value
      dummy.select()
      document.execCommand('copy')
      document.body.removeChild(dummy)
      Vue.set(this.copied, instanceCopy, true)
    },
    objectListener ({ data }) {
      if (data.type === EDIT_OBJECT) {
        Vue.set(this, 'objectPosition', data.position)
        Vue.set(this, 'selectedObject', _.cloneDeep(data.object))
        this.editComponent(data.position, data.object)
      } else if (data.type === EMBEBED_OBJECT) {
        this.embebedObject(data.object)
      } else if (data.type === MOVE_LEFT || data.type === MOVE_UP) {
        this.moveComponent(data.position, data.position - 1)
      } else if (data.type === MOVE_RIGHT || data.type === MOVE_DOWN) {
        this.moveComponent(data.position, data.position + 1)
      } else if (data.type === DELETE_OBJECT) {
        this.deleteComponent(data.position)
      } else if (data.type === CLONE_OBJECT) {
        this.addComponent(data.object)
      }
    },
    moveComponent (lastPosition, newPosition) {
      Store.commit({
        type: DESIGN.MUTATIONS.MOVE_OBJECT,
        view: this.selectedPage,
        lastPosition,
        newPosition
      })
      this.realoadView(this.selectedPage)
    },
    async embebedObject (object) {
      const { connection } = object
      let params = {}
      if (connection) {
        const connections = await this.$servicesPLK.connection.getConnections()
        params.connection = _.cloneDeep(connections.find(c => c.id === connection))
      }
      const filterRelations = await this.$servicesPLK.filters.getRelations()
      if (filterRelations) {
        params.filterRelations = _.cloneDeep(filterRelations)
      }
      params.plkparams = _.cloneDeep(object)
      params.styles = (Store.getters[DESIGN.GETTERS.GET_FILE_CONTENT](APP_STYLES_CONFIG_FILE) || {}).data || ''
      params.colors = (Store.getters[DESIGN.GETTERS.GET_FILE_CONTENT](APP_COLORS_CONFIG_FILE) || {}).data || ''
      this.embebedData = JSON.stringify(params, null, '\t').replaceAll('\\n', '')
      Vue.set(this, 'copied', {})
      this.$modal.show('show-embebed-code')
    },
    async embebedPage (idPage) {
      const page = Store.getters[DESIGN.GETTERS.GET_VIEW](idPage)
      const components = page.components || []
      const connections = components.map(component => component?.connection).filter(Boolean)
      let params = {}
      if (connections.length) {
        const connectionsDefinition = await this.$servicesPLK.connection.getConnections()
        params.connection = _.cloneDeep(connectionsDefinition.filter(c => connections.includes(c.id)))
      }
      const filterRelations = await this.$servicesPLK.filters.getRelations()
      if (filterRelations) {
        params.filterRelations = _.cloneDeep(filterRelations)
      }
      params.viewClass = page.viewClass || ''
      params.actions = _.cloneDeep(page.actions || [])
      params.plkparams = _.cloneDeep(components)
      params.styles = (Store.getters[DESIGN.GETTERS.GET_FILE_CONTENT](APP_STYLES_CONFIG_FILE) || {}).data || ''
      params.colors = (Store.getters[DESIGN.GETTERS.GET_FILE_CONTENT](APP_COLORS_CONFIG_FILE) || {}).data || ''
      params.colors = (Store.getters[DESIGN.GETTERS.GET_FILE_CONTENT](APP_COLORS_CONFIG_FILE) || {}).data || ''
      params.indexjs = (Store.getters[DESIGN.GETTERS.GET_FILE_CONTENT](APP_JAVASCRIPT_CONFIG_FILE) || {}).data || ''
      this.embebedData = JSON.stringify(params, null, '\t').replaceAll('\\n', '')
      Vue.set(this, 'copied', {})
      this.$modal.show('show-embebed-code')
    },
    addComponent (object) {
      if (object) {
        Store.commit({
          type: DESIGN.MUTATIONS.ADD_OBJECT,
          view: this.selectedPage,
          object
        })
        this.realoadView(this.selectedPage)
      }
    },
    deleteComponent (position) {
      if (position !== undefined) {
        this.$modal.show('dialog', {
          title: this.$t('designer.design.deleteComponent.title'),
          text: this.$t('designer.design.deleteComponent.text'),
          buttons: [
            {
              title: this.$t('designer.design.deleteComponent.modal.cancel'),
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            {
              title: this.$t('designer.design.deleteComponent.modal.confirm'),
              handler: () => {
                Store.commit({
                  type: DESIGN.MUTATIONS.DELETE_OBJECT,
                  view: this.selectedPage,
                  position
                })
                this.$modal.hide('dialog')
                this.realoadView(this.selectedPage)
              }
            }
          ]
        })
      }
    },
    realoadView (newPage) {
      Vue.nextTick(() => {
        Vue.set(this, 'selectedPage', undefined)
        Vue.nextTick(() => {
          Vue.set(this, 'selectedPage', newPage)
        })
      })
    },
    saveConfig ({ model }) {
      Store.commit({
        type: DESIGN.MUTATIONS.UPDATE_OBJECT,
        view: this.selectedPage,
        position: this.objectPosition,
        object: model
      })
      this.realoadView(this.selectedPage)
      Vue.set(this, 'showConfig', false)
    },
    changeView (type) {
      Vue.set(this, 'typeView', type)
    }
  },
  watch: {
    showConfig () {
      this.$refs.bodyContent.scrollTop = 0
      if (!this.showConfig) {
        setTimeout(() => {
          // Tenemos un timeout para que durante la animación no se oculte
          Vue.set(this, 'selectedObject', undefined)
        }, 1000)
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .plk_design {
    background: #f8f9fa;
    max-height: 100vh;
    overflow: hidden;
    .vue-dialog-content-title {
      font-size: x-large;
    }

    input[type="submit"] {
      padding: 0 10px;
      background-color: $color-base;
      border-color: $color-basedark;
      color: $color-base-contrast;
    }
    input[type="submit"]:hover {
      background-color: $color-basedark;
    }

    .config_content {
      display: table;
      width: 100%;
    }

    .config_area {
      .fixed {
        position: absolute;
        z-index: 9;
      }
      .fixed.pull-right {
        right: 0;
      }
      position: fixed;
      height: 100vh;
      z-index: 999;
      width: 75%;
      bottom: 0;
      left: 76%;
      margin-left: 25%;
      background: #ffffff;
      border-top: solid 1px #dee2e6;
      -webkit-transition: left 1s ease;
      -moz-transition: left 1s ease;
      -ms-transition: left 1s ease;
      -o-transition: left 1s ease;
      transition: left 1s ease;
      .body {
        padding: 1rem;
        height: 100%;
        overflow: scroll;
      }
      @media (max-width: breakpoint-max('sm', $grid-breakpoints)) {
        & {
          width: 100%;
          margin-left: 0;
          left: 100%;
        }
      }
    }
    .config_area.u-full-width {
      width: 100%;
      left: 0;
      margin: 0;
    }
    .config_area.maximize {
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      margin: 0;
    }
    .config_area.show {
      left: 0;
      top: 0;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    }
    .viewer {
      padding: 3vh;
      padding-left: calc(3vh + 50px);
    }
    .window_creator {
      height: 94vh;
      border: 1px solid #dee2e6 !important;
      margin: auto;
      background: white;
    }
    .window_creator.full_screen {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      margin-top: 0;
      z-index: 30;
      .bar_icons {
        display: none;
      }
      .address_bar {
        .navbar {
          margin-right: 25%;
        }
      }
    }
    .html_content {
      height: calc(100% - 49px);
      overflow: hidden;
    }
    .browser_desktop {
      width: 100%;
    }
    .justify_content_between {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .address_bar {
      height: 50px;
      padding: 0 14px;
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      background-color: #f8f9fa;
      border-bottom: 1px solid #dee2e6 !important;
      .navbar {
        width: 50%;
      }
    }
    .bar_dots {
      span {
        display: inline-block;
        width: 16px;
        height: 15px;
        border-radius: 50%;
        margin: 18px 8px 0 0;
        background-color: #dee2e6;
        cursor: pointer;
      }
      .maximize {
        &:hover {
          background: #68d268;
        }
      }
      .openNewTab {
        &:hover {
          background: #ffaf19;
        }
      }
    }
    .fas {
      cursor: pointer;
    }
    .bar_icons {
      i {
        &:hover {
          color: #000000;
        }
        width: 16px;
        height: 15px;
        margin: 12px 4px 0 0;
        cursor: pointer;
        padding: 5px;
        color: #b5b5b5;
      }
      i.selected {
        color: black;
      }
    }
    .object_list {
      position: fixed;
      left: 5px;
      bottom: 5px;
      z-index: 999;
    }
    @media (max-width: breakpoint-max('sm', $grid-breakpoints)) {
      .bar_dots {
        display: none;
      }
      .bar_icons {
        display: none;
      }
      .address_bar {
        .navbar {
          width: 100%;
        }
      }
    }
    @media (min-width: breakpoint-min('sm', $grid-breakpoints)) {
      .browser_tablet {
        width: 100%;
        max-width: 778px;
      }
      .browser_mobile {
        width: 100%;
        max-width: 375px;
      }
    }
  }
</style>
