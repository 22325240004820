<template>
  <div class="plk_configuration">
    <div class="tabs">
      <span @click="selectedTab = group" :class="{ 'selected': selectedTab === group }" class="tab" v-for="group in configGroups" :key="group">{{$t(`configuration.${group}`)}}</span>
      <span @click="selectedTab = showCodeOption" :class="{ 'selected': showCode }" class="tab">{{$t('configuration.showcode')}}</span>
    </div>
    <form @submit.prevent="save">
      <div v-for="(field, index) in selectedFields" :key="index" class="editzone">
        <label class="title" v-if="field.plkparams.title">{{$t(field.plkparams.title)}}</label>
        <component class="component" :global="model" @changeValue="changeValue(field.field, $event)" :selectedCube="principalCube" @selectCube="selectCube" @unselectCube="unselectCube" v-if="getInstance(field.type)" v-bind:is="getInstance(field.type)" :plkparams="field.plkparams" :default="defaultValues[field.field] === undefined ? field.default : defaultValues[field.field]"></component>
      </div>
      <textarea v-if="showCode" v-model="codestr" class="u-full-width" required></textarea>
      <button type="submit" class="mt-2 mb-5 button pull-right">{{$t('configuration.save')}}</button>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'
import { addValueInKey, valueByString } from '@/plugins/object'
import i18n from './i18n'
const SELECTED_CUBE_PARAM = 'selectedCube'
const SELECTED_NUMBER_CUBE_PARAM = 'selectedNumberCube'
const INITIAL_FILTER = '1_query'
const SHOWCODE_OPTION = 'showcode'
export default {
  name: 'configuration',
  props: {
    initValue: {
      type: Object,
      required: false
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      instances: {},
      defaultValues: {},
      model: {},
      codestr: '',
      selectedTab: INITIAL_FILTER
    }
  },
  computed: {
    showCode () {
      return this.fields.length === 0 || this.selectedTab === 'showcode'
    },
    selectedFields () {
      return this.fields.filter(f => f.group === this.selectedTab || (f.group === undefined && this.selectedTab === INITIAL_FILTER))
    },
    configGroups () {
      return Object.values(this.fields.reduce((r, e) => {
        r[e.group || INITIAL_FILTER] = (e.group || INITIAL_FILTER).toLowerCase()
        return r
      }, {})).sort()
    },
    disableOptions () {
      return !this.fields.length
    },
    package () {
      return this.packages[this.type] || {}
    },
    fields () {
      return (this.package.fields || [])
    },
    principalCube () {
      return this.model[SELECTED_CUBE_PARAM]
    },
    showCodeOption () {
      return SHOWCODE_OPTION
    }
  },
  methods: {
    save () {
      if (this.showCode || this.disableOptions) {
        this.setModel(this.codestr)
      }
      Vue.delete(this.model, 'plkNoEdited')
      this.$emit('changeValue', { model: this.model })
    },
    changeValue (field = '', change = {}) {
      if (field) {
        Vue.set(this, 'model', addValueInKey(_.cloneDeep(this.model), field, change.value))
      }
    },
    selectCube ({ cube }) {
      if (cube) {
        Vue.set(this, 'model', addValueInKey(_.cloneDeep(this.model), SELECTED_CUBE_PARAM, cube))
        Vue.set(this, 'model', addValueInKey(_.cloneDeep(this.model), SELECTED_NUMBER_CUBE_PARAM, (this.model[SELECTED_NUMBER_CUBE_PARAM] || 0) + 1))
      }
    },
    unselectCube () {
      Vue.set(this, 'model', addValueInKey(_.cloneDeep(this.model), SELECTED_NUMBER_CUBE_PARAM, this.model[SELECTED_NUMBER_CUBE_PARAM] - 1))
      if (this.model[SELECTED_NUMBER_CUBE_PARAM] === 0) {
        Vue.delete(this.model, SELECTED_CUBE_PARAM)
        Vue.delete(this.model, SELECTED_NUMBER_CUBE_PARAM)
      }
    },
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.refreshModel()
    },
    refreshModel () {
      Vue.set(this, 'model', this.initValue || {})
      this.setCodeStr(this.model)
      this.loadPackage(this.type)
    },
    setCodeStr (model) {
      Vue.set(this, 'codestr', JSON.stringify(model, null, 2))
    },
    setModel (codestr) {
      const model = this.parseModel(codestr)
      if (model) {
        Vue.set(this, 'model', model)
      }
    },
    parseModel (codestr) {
      try {
        return JSON.parse(codestr)
      } catch (e) {}
      return undefined
    },
    getInstance (type = '') {
      return this.instances[this.$servicesPLK.component.normalizeType(type)]
    },
    loadComponentsByTypes () {
      this.fields.forEach(param => {
        this.$servicesPLK.component.loadComponent(param.type, this.instances, this.$servicesPLK.component.normalizeType(param.type))
      })
    },
    addDefaultVars (values) {
      if (values) {
        this.fields.forEach(field => {
          Vue.set(this.defaultValues, field.field, valueByString(values, field.field))
        })
      }
    }
  },
  watch: {
    configGroups () {
      if (!this.configGroups.includes(INITIAL_FILTER)) {
        if (this.configGroups.length === 0) {
          this.selectedTab = SHOWCODE_OPTION
        } else {
          this.selectedTab = this.configGroups[0]
        }
      }
    },
    type () {
      this.loadPackage(this.type)
    },
    package () {
      this.loadComponentsByTypes()
      this.addDefaultVars(this.initValue)
    },
    initValue () {
      this.refreshModel()
    },
    showCode () {
      if (this.showCode) {
        this.setCodeStr(this.model)
      } else {
        const newModel = this.parseModel(this.codestr)
        if (newModel) {
          this.addDefaultVars(newModel)
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .plk_configuration {
    input,
    select {
      height: 25px;
      line-height: 25px;
      margin-bottom: 0px;
      &:focus {
        border: 1px solid $color-focus;
      }
    }
    select {
      padding: 0px 6px;
    }
    .vs__dropdown-toggle {
      min-height: 25px;
      margin: 0;
      padding: 0;
    }
    .vs__actions {
      height: 18px;
    }
    .vs__selected {
      margin-bottom: 4px;
    }
    .vs__dropdown-toggle input {
      height: 20px;
      margin: 0;
    }
  }
</style>

<style scoped lang="scss">
  .tabs {
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    gap: 1rem;
    padding: 0 .5rem;
    border-bottom: solid #efefef 1px;
    .tab {
      height: 4rem;
      line-height: 4rem;
      display: flex;
      padding: 0 .5rem;
      cursor: pointer;
      vertical-align: middle;
      &.selected,
      &:hover {
        border-bottom: solid $color-base 1px;
        font-weight: bold;
        color: $color-base;
      }
    }
  }
  .u-full-width {
    resize: vertical;
    min-height: 50vh;
  }
  i.disabled {
    color: grey;
    cursor: not-allowed !important;
    pointer-events: none;
  }
  .plk_configuration {
    padding-top: 6rem;
    .editzone {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      padding-bottom: 1.5rem;
      font-size: 1.5rem;
      &:last-of-type {
        border-bottom: 0;
        margin-bottom: 1rem;
        padding-bottom: 0;
      }
      @media (max-width: breakpoint-max('sm', $grid-breakpoints)) {
        flex-direction: column;
      }
      .title {
        flex: 1;
        color: #6f6f6f;
        margin-right: 10px;
        line-height: 12px;
      }
      .component {
        flex: 9;
      }
    }
  }
</style>
